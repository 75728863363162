import {createRouter, createWebHistory} from 'vue-router';
import TopPage from './views/TopPage.vue';
import NewsList from './views/NewsList.vue';
import NewsDetail from './views/NewsDetail.vue';
import InquiryPage from './views/InquiryPage.vue';
import PageNotFound from './views/PageNotFound.vue';


const routes = [
  {
    path: '/',
    component: TopPage,
  },
  {
    path: '/news_list',
    component: NewsList,
  },
  {
    path: '/news/:id',
    component: NewsDetail,
  },
  {
    path: '/inquiry/:sent',
    component: InquiryPage,
  },
  { 
    path: '/:catchAll(.*)', 
    component: PageNotFound,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }else {
      return { top: 0 }
    }
  },
});

export default router;