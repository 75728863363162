import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import dayjs from 'dayjs';
import './assets/styles/color.css';

window.$ = window.jQuery = window.jquery = require("jquery");
window._ = require('lodash');

const app = createApp(App);
app.config.globalProperties.dayjs = dayjs
app.use(router).mount('#app')
