<template>
<!-- TOPICS -->
<section id="topics" class="bg-white mb-5">
    <div class="wrapper">
        <h2 class="fw-bolder my-3 fs-1">TOPICS</h2>

        <ul class="me-2 row">

          <li  v-for="news in news_list" :key="news" class="col-12 my-2 fs-4">
            <a @click="view_news(news.id)" class="row">
              <div class="col-md-3 col-12">
                <span class="box">■</span>
                <span class="me-3">{{ convertToJapaneseDate(news.publishedAt) }}</span>
              </div>
              <div class="col-md-9 col-12">
                <span>{{ news.title }}</span>
              </div>
            </a>
          </li>

        </ul>
    </div>
</section>
</template>

<script>
import _ from 'lodash';
import {Api, Http} from '@/utiles/ApiConnect';

export default {
  data: function() {
    return {
      // Webinarお知らせ一覧
      webinar_information: [],
      news_list: [],
    };
  },

  components: {
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.get_news_list();
  },

  /***
   * メソッド
   */
  methods: {
    get_news_list: function() {
      const { createClient } = require('microcms-js-sdk');
      const client = createClient({ serviceDomain: 'bioplastic-forum', apiKey: 'ud1EtkrDQv7co8DSgUIQJTevuLwZvjI3BjD5' });
      // service-domain は XXXX.microcms.io の XXXX 部分
      client
        .get({
          endpoint: 'news',
        })
        .then((res) => { 
          this.news_list=res.contents;
        })
        .catch((err) => console.log(err));
    },
    view_news(news_id) {
      this.$router.push('/news/' + news_id);
    },
    view_inquiry() {
      this.$router.push('/inquiry/0');
    },
    convertToJapaneseDate(dateString) {
      // Parse the input date string
      const date = new Date(dateString);

      // Extract the year, month, and day
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are 0-based, so add 1
      const day = date.getDate();

      // Format the date in Japanese template
      const japaneseDate = `${year}年${month}月${day}日`;

      return japaneseDate;
    },
  },
};
</script>
<style lang="scss">

</style>