<template>
    <section id="not-found" class="text-center p-3">
        <div class="my-auto">
            <h1 class="fw-bolder mb-3">
                404
            </h1>
            <h2 class="fs-2 fw-bolder ">
                ページが見つかりませんでした
            </h2>
        </div>
    </section>
  </template>
  
<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";
#not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color:var(--subtitle-color);
    h1{
        font-size: 10rem;
        text-shadow: .5rem .5rem rgba(1,1,1,.5);
        @include media-breakpoint-down(md) {
            font-size: 6.5rem;
        }
    }
}
</style>