/*****************************************************************************/
//
// API接続クラスサンプル
// ※ jQueryライブラリ必須
//
import $ from 'jquery'
const { createClient } = require('microcms-js-sdk');

// API接続URL
// const API_URL = 'https://webinarweek';
const API_URL = 'https://webinarweek.net';


// エンドポイント
const END_POINT = '/website/api/v1.0.json';

// ウェビナー見本市テーマID
// const THEMA_ID = 'TH_6RO47nep';
// const THEMA_ID = 'TH_vcll56kP';
const THEMA_ID = 'TH_Dh3U0kJc';

class ApiConnect {
    //コンストラクタ
    constructor() {}

    //API接続
    connect(params) {

    }
}
const Api = new ApiConnect();


/*****************************************************************************/
//
// HTTP関連 Util
//
class HttpUtil {
    //コンストラクタ
    constructor(locale, currencyCode) {
        this.locale = locale;
    }
    query(key, url) {
        if (!url) url = window.location.href;

        key = key.replace(/[[]]/g, "\\$&");
        var regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
}
const Http = new HttpUtil();

export { Api, Http }