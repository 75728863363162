<template>
<div id="topics" class="bg-white mb-5">
  <h1 class="text-center my-3 fw-bold">TOPICS</h1>
  <div id="contens" class="shadow-sm border p-3 mx-3 mx-md-auto">
    <div>
      <!-- タイトル -->
      <h3 class="text-center fs-1 fw-bold">
        {{news.title}} 
        <!-- {{ this.$route.params.id }} -->
      </h3>
      <!-- 公開日 -->
      <p id="date" class="border-bottom">
        {{ convertToJapaneseDate(news.publishedAt) }}
      </p>
    </div>
    <!-- コンテンツ -->
    <div id="details">
      <p v-html="news.content"></p>
    </div>
    <div id="nav-link" class="text-start mx-3">
      <a @click="view_news_list()">TOPICS一覧</a>
    </div>
  </div>
</div>
</template>

<script>
import {Api, Http} from '@/utiles/ApiConnect';
import _ from 'lodash';

export default {
  data: function() {
    return {
    // お知らせ
    news: {},
    };
  },

  components: {
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    // this.getNewsInfo();
    this.get_news_list();
  },

  /***
   * メソッド
   */
  methods: {
    
    get_news_list: function() {
      const { createClient } = require('microcms-js-sdk');
      const client = createClient({ serviceDomain: 'bioplastic-forum', apiKey: 'ud1EtkrDQv7co8DSgUIQJTevuLwZvjI3BjD5' });
      // service-domain は XXXX.microcms.io の XXXX 部分
      client
        .get({
          endpoint: 'news',
        })
        .then((res) => { 
          this.news = res.contents.find(record => record.id === this.$route.params.id);
          console.log(this.news);
        })
        .catch((err) => console.log(err));
    },
    convertToJapaneseDate(dateString) {
      // Parse the input date string
      const date = new Date(dateString);

      // Extract the year, month, and day
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are 0-based, so add 1
      const day = date.getDate();

      // Format the date in Japanese template
      const japaneseDate = `${year}年${month}月${day}日`;

      return japaneseDate;
    },
    // news詳細へ
    view_news_list() {
      this.$router.push('/news_list/');
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";
/* topics */
#topics { 
  background-color: var(--bg-1);
  h1{
    color: var(--subtitle-color);
    letter-spacing: 10px;
    text-align: center;
  }
  #contens{
    width: 75%;
    @include media-breakpoint-down(md) {
    width: initial;
    }
    #date{
      color: gray;
    }
    #details{
      h3{
        border-left: solid 4px var(--subtitle-color);
        padding-left: 4px;
      }
      img{
        height: auto;
        max-width: 420px;
      }
    }
  }
}
</style>