<template>
  <BaseHeader></BaseHeader>
  <div id="anchor_top"></div>
  <router-view style="min-height:70vh;"></router-view>
  <BaseFooter></BaseFooter>
</template>

<script>
import BaseHeader from './components/BaseHeader.vue';
import BaseFooter from './components/BaseFooter.vue';

export default {
  name: 'App',
  components: {
  BaseHeader,
  BaseFooter,
  },
};
</script>

<style lang="scss">
@import "../node_modules/mdb-vue-ui-kit/src/scss/index.free";
html{
  overflow-x:hidden;
  font-family: "meiryo" !important;
}

div{
  overflow: hidden;
}

.no-gutter{
  padding: 0px !important;
  margin: 0px !important;
}

h1,h2,h3,h4,h5,h6{
  margin:0px !important;
}

div,p,span,a,h1,h2,h3,h4,h5,h6{
  font-family: "meiryo" !important;
}

.wrapper{
  max-width: 1200px;
  margin: 0 auto 0 auto !important;
}

.section-wrapper{
  max-width: 1500px;
  margin: 0 auto 0 auto !important;
}

a.hvr-zoom img{
  cursor: pointer;
  transition: all ease 0.3s;
}

a.hvr-zoom:hover img{
  transform: scale(1.01);
}
// top page 
</style>
